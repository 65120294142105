import { template as template_64c3e735480e4dc38de130422b6a7a16 } from "@ember/template-compiler";
const SidebarSectionMessage = template_64c3e735480e4dc38de130422b6a7a16(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
