import { template as template_a8573cec51f548019642e720e6575421 } from "@ember/template-compiler";
const FKLabel = template_a8573cec51f548019642e720e6575421(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
