import { template as template_a9a4df2e58f047709bc4f2c6adb5e547 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const PostMenuCopyLinkButton = template_a9a4df2e58f047709bc4f2c6adb5e547(`
  <DButton
    class="post-action-menu__copy-link"
    ...attributes
    @action={{@buttonActions.copyLink}}
    @icon="d-post-share"
    @label={{if @showLabel "post.controls.copy_action"}}
    @title="post.controls.copy_title"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PostMenuCopyLinkButton;
