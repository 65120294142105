import { template as template_e9d01034ce174c7a8188c5f341fcc75a } from "@ember/template-compiler";
const FKControlMenuContainer = template_e9d01034ce174c7a8188c5f341fcc75a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
